import React from "react";
import Layout from "../components/Layout/layout"
import CoverImage from "../components/Home/coverImage";
import Start from '../components/Home/start';

const HomePage = () => (
  <div>
    <CoverImage />
    <Layout>            
      <Start />      
    </Layout>
  </div>
);

export default HomePage;