import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image";
import styled from "styled-components"

const Container = styled.div`
width: 100%;
height: 100vh;
background-color: white;
`;

const Centered = styled.div`
margin: 0;  
text-align: center;
position: absolute;
top: 50%;
left: 50%;
margin-right: -50%;
transform: translate(-50%, -50%);
`;

const Button = styled(props => <Link {...props} />)`
  display: inline-block;
  border-radius: 3px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 11rem;
  background: white;
  color:black;
  font-size: 18px;
  border: 1px solid white;    

  :hover {    
    background: black;
    color: white;   
    opacity:0.9;    
  }
`

const CoverImage = () => {

    const data = useStaticQuery(graphql`
    query {    
        fileName: file(relativePath: { eq: "bg2.jpg" }) {
          childImageSharp {       
            fluid(quality: 75, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
               
            }
          }
        }  
      }
  `)

    return (<Container>
        <Img
            title="Home"
            alt="Home page hero"
            fluid={data.fileName.childImageSharp.fluid}
            imgStyle={{
                objectPosition: 'left',
            }}
            style={{
                width: "100%",
                height: "100%"
            }}
        />
        <Centered>            
            <Button to="/#start">Start Your Journey</Button>
        </Centered>
    </Container>
    )


}

export default CoverImage;
