import React from "react";
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Heading, Title, TwoColContainer, Col1Image, Col2Info, Text, NavLink } from '../Layout/pageParts';
import Profile from '../profile';

const Start = () => {
  const data = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "Journey1.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
                  ...GatsbyImageSharpFluid

          }
        }
      }
    }
`)

  return (
    <TwoColContainer id="start">
      <Col1Image>
        <Img alt="profile photo" fluid={data.fileName.childImageSharp.fluid} style={{ width: "100%", height: "100%" }} imgStyle={{ objectPosition: 'bottom' }} />
      </Col1Image>
      <Col2Info>
        <Heading>JOIN OUR BEAUTIFUL COMMUNITY</Heading>
        <Title>Let me be your wellness advocate and set you on the road to a healthier and happier life using essential oils</Title>
        <Profile />
        <Text>
          <br/>          
        See below for a few ideas.
        <br/>
        <div>
        <ul>
          <li>Peppermint: used to boost energy and aid digestion.</li>
          <li>Lavender: used to relieve stress.</li>
          <li>Sandalwood: used to calm nerves and help with focus.</li>
          <li>Bergamot: used to reduce stress and improve skin conditions like eczema.</li>
          <li>Rose: used to improve mood and reduce anxiety.</li>
          <li>Chamomile: used to improve mood and relaxation.</li>
          <li>Ylang-Ylang: used to treat headaches, nausea, and skin conditions</li>
          <li>Tea Tree: used to fight infections and boost immunity</li>
        </ul>
        </div>
        </Text>
        <Text>
        My vision as your wellness advocate is to share this knowledge and foster a tribe of like minded
          people who support and help each other. 
        </Text>
        <Text>
          If this sounds like something you might be interested in then <NavLink to="/contact">get in touch for a free wellness consultation</NavLink> or go 
          <NavLink to="/joinUs"> here to join us</NavLink>. What are you waiting for, lets
          start you on the journey!
        </Text>
      </Col2Info>
    </TwoColContainer>
  )
}

export default Start;